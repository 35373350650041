<template>
  <div class="shoppings com-overflow-hidden" style="padding: 10px 0">
    <div>
      <div class="hd com-flex" style="display: flex">
        <span class="car">确认收货地址<span v-show="shopItems.length !== 0"></span></span>
        <div style="display: flex; align-items: center">
          <div
            class="cart-sum"
            style="margin-right: 20px"
            @click="dialogTableVisibles = true"
          >
            <span>修改该地址</span>
          </div>
          <div class="cart-sum" style="margin-right: 20px">
            <span>设置为默认地址</span>
          </div>
          <div class="cart-sum" @click="dialogTableVisible = true">
            <span>管理收货地址</span>
          </div>
        </div>
      </div>
      <div style="margin-left: 120px">
        <el-radio-group v-model="radio">
          <div>
            <div style="padding: 10px 0">
              <div>
                <el-radio :label="1">
                  <span style="font-size: 15px; font-weight: 700"
                    >广东省广州市天河区燕岭路633号(xxx收)13266479854</span
                  ></el-radio
                >
              </div>
            </div>
            <div style="padding: 10px 0">
              <div>
                <el-radio :label="2"
                  ><span style="font-size: 15px; font-weight: 700"
                    >广东省广州市天河区燕岭路燕塘燕岭路25号(xxx收)13266479854</span
                  ></el-radio
                >
              </div>
            </div>
            <div style="padding: 10px 0">
              <div>
                <el-radio :label="3"
                  ><span style="font-size: 15px; font-weight: 700"
                    >广州市天河区天源路1190号(xxx收)13266479854</span
                  ></el-radio
                >
              </div>
            </div>
            <div style="padding: 10px 0">
              <div>
                <el-radio :label="4"
                  ><span style="font-size: 15px; font-weight: 700"
                    >广东省广州市白云区鹤龙二路85号(xxx收)13266479854</span
                  ></el-radio
                >
              </div>
            </div>
          </div>
        </el-radio-group>
      </div>
    </div>
    <div>
      <div class="hd com-flex" style="display: flex">
        <span class="car"
          >商品<span v-show="shopItems.length !== 0"
            >&nbsp;（全部{{ shopItems.length }}）</span
          ></span
        >
        <div class="cart-sum">
          <span>已选商品（不包含运费）价格</span>
          <strong><em>&nbsp;&yen;</em>{{ allShop }}</strong>
        </div>
      </div>
      <div class="cartmain">
        <div class="cart-table-th com-flex" style="display: flex">
          <div class="th th-info" style="text-align: center">商品信息</div>
          <div class="th th-price">单价</div>
          <div class="th th-amount">数量</div>
          <div class="th th-sum">金额</div>
          <div class="th th-op">操作</div>
        </div>
        <div class="cart-list">
          <div class="default-list" v-if="!shopItems.length">您的购物车是空的~</div>
          <ShopItem
            v-for="(item, index) in shopItems"
            :key="index"
            :shopIt="item"
            @BackFather="sumChange"
            style="margin: 20px 55px; width: 1250px"
            @BackFathers="sumChanges"
          >
          </ShopItem>
        </div>
        <div style="display: flex; justify-content: space-between">
          <div></div>
          <div>
            <el-button type="success">返回购物车</el-button>
            <el-button type="primary" @click='dialogTableVisiblep=true'>提交订单</el-button>
          </div>
        </div>
      </div>
      <el-dialog
        title="地址管理"
        :visible.sync="dialogTableVisible"
        width="1200px"
        append-to-body
      >
        <el-form label-width="120px" ref="form" :model="form">
          <el-row align="middle" :gutter="0">
            <el-col :span="6">
              <el-form-item label="收货人" prop="poMainName">
                <el-input placeholder="请输日期" v-model="form.poMainName2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系电话" prop="poMainName">
                <el-input placeholder="请输入公司" v-model="form.poMainName1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否为默认地址" prop="poMainName">
                <el-radio-group v-model="radio">
                  <el-radio :label="3">是</el-radio>
                  <el-radio :label="6">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="收货地址" prop="poMainName">
                <el-input
                  placeholder="请输入询价单"
                  v-model="form.poMainName3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="display: flex; align-items: center; justify-content: center">
          <div>
            <el-button type="primary">保存地址</el-button>
          </div>
        </div>
        <el-table :data="tableData" border style="width: 100%; margin-top: 40px">
          <el-table-column prop="name" align="center" label="收货人" width="180">
          </el-table-column>
          <el-table-column prop="address" label="地址" align="center"> </el-table-column>
          <el-table-column prop="addressNumber" label="邮编" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="联系电话" align="center">
          </el-table-column>
          <el-table-column align="center" width="100" label="操作" fixed="right">
            <template #default="{ row }">
              <div style="display: flex; flex-wrap: wrap; justify-content: center">
                <div style="margin-right: 10px; margin-left: 10px">修改</div>
                <div>删除</div>
                <div style="margin-left: 8px">设置为默认</div>
              </div>
              <!-- <el-tooltip effect="dark" content="启用" placement="top" v-hasPermi="['basics:basicsPost:delete']">
            <el-button icon="el-icon-document-delete" type="primary" size="mini" circle @click="handleDelete(row)"></el-button>
          </el-tooltip> -->
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        title="地址修改"
        :visible.sync="dialogTableVisibles"
        width="1200px"
        append-to-body
      >
        <el-form label-width="120px" ref="form" :model="form">
          <el-row align="middle" :gutter="0">
            <el-col :span="6">
              <el-form-item label="收货人" prop="poMainName">
                <el-input placeholder="请输日期" v-model="form.poMainName2"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系电话" prop="poMainName">
                <el-input placeholder="请输入公司" v-model="form.poMainName1"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否为默认地址" prop="poMainName">
                <el-radio-group v-model="radio">
                  <el-radio :label="3">是</el-radio>
                  <el-radio :label="6">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="收货地址" prop="poMainName">
                <el-input
                  placeholder="请输入询价单"
                  v-model="form.poMainName3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="display: flex; align-items: center; justify-content: center">
          <div>
            <el-button type="primary">保存地址</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <el-dialog title="支付详情" :visible.sync="dialogTableVisiblep" width="550px"  append-to-body>
      <div style="display: flex">
        <div>
          <div style="margin-left: 31px">支付宝支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
        <div>
          <div style="margin-left: 31px">微信支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <el-button type="success" @click="dialogTableVisiblea = true"
          >上传支付凭证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="上传凭证" :visible.sync="dialogTableVisiblea" width="550px"  append-to-body> 
      <el-upload action="#" list-type="picture-card" :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import ShopItem from "../Shoping/ShopItems.vue";

export default {
  name: "ShoppingVue",
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "广东省广州市天河区燕岭路633号",
          phone: 13266479854,
          addressNumber: "00000",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
          phone: 13266479854,
          addressNumber: "00000",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
          phone: 13266479854,
          addressNumber: "00000",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
          phone: 13266479854,
          addressNumber: "00000",
        },
      ],
      radio: "",
      form: {},
      radio: "",
      isHome: false,
      isActive: false,
      isAllSelected: false,
      dialogTableVisible: false,
      dialogTableVisibles: false,
      dialogTableVisiblep: false,
      dialogTableVisiblea: false,
      allShop: "0.00",

      shopItems: [
        {
          checked: false,
          type: 1,
          img:
            "https://www.gqedu.org.cn:6868/manage/72411650fc654bd3a7c9747904612799.JPG",
          content: "CHRISBELLA-双手腕女包-BG012211222",
          price: 4100,
          num: 300,
          id: "62371932",
          show: false,
        },
        {
          checked: false,
          type: 2,
          img:
            "https://www.gqedu.org.cn:6868/manage/8e7f8f051fe345f29c54c9ecf9cf5a72.JPG",
          content: "BAGCO-单手腕女包-EK012212003",
          price: 4100,
          num: 300,
          id: "62371932",
          show: false,
        },
      ],
    };
  },
  components: {
    ShopItem,
  },

  created() {
    this.sumContent();
  },
  methods: {
    sumContent() {
      this.shopItems.forEach((item) => {
        this.allShop = item.num * item.price + this.allShop * 1;
      });
      console.log(this.allShop);
    },
    sumChange(a, b) {
      console.log("3213123");
      console.log(a, b);
      if (a == 1) {
        this.allShop = this.allShop * 1 + b * 1;
      } else {
        this.allShop = this.allShop * 1 - b * 1;
      }
    },
    sumChanges() {
      // if(this.isAllSelected)
      this.allShop = 0;

      this.shopItems.forEach((item) => {
        item.checked = true;
        this.allShop = item.price * 1 * item.num * 1 + this.allShop * 1;
      });
    },
    deleteMultipe() {},
    AllSelectedClick() {
      // if(this.isAllSelected)
      this.allShop = 0;
      if (this.isAllSelected == false) {
        this.isActive = true;
        this.shopItems.forEach((item) => {
          item.checked = true;
          this.allShop = item.price * 1 * item.num * 1 + this.allShop * 1;
        });
      } else {
        this.isActive = false;
        this.shopItems.forEach((item) => {
          item.checked = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}

.shoppings {
  // background: repeat-y url('https://www.gqedu.org.cn:6868/manage/716bbd2b57c841ccbac91d12a4ea907d.png');
  // background-size: cover;

  .content {
    background-color: #fff;
    border-radius: 24px;
    margin-bottom: 45px;
    font-family: "Microsoft YaHei";
    padding-bottom: 10px;
  }
}

.hd,
.cart-table-th {
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
}

.hd {
  height: 29px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;

  .car {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .cart-sum {
    strong {
      font-size: 22px;
      font-weight: 700;
      color: #f50;
      margin-right: 12px;
    }

    a {
      $h: 42px;
      display: inline-block;
      text-align: center;
      line-height: $h;
      @include wh(74px, $h);
      background: #aaa;
      color: #fff;
      font-size: 16px;
      border-radius: 21px;
      cursor: not-allowed;
    }

    .active {
      background-color: #f50;
      cursor: pointer;
    }
  }
}

.cartmain {
  user-select: none;
  $h: 50px;

  .cart-table-th {
    padding: 0 55px;
    height: $h;
    margin-bottom: 15px;
  }

  .th {
    height: 100%;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 14px;
    line-height: $h;
  }

  .default-list {
    color: #aaaaaa;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 300px;
  }
}
</style>
<style lang="scss">
.th {
  flex: 2;
}

.th-chk {
  flex: 1.2;

  // width: 150px;
  input,
  label {
    cursor: pointer;
  }

  input {
    vertical-align: middle;
    margin-right: 8px;
  }

  label {
    width: 50px;
    height: 50px;
    display: inline-block;
  }
}

.th-info {
  flex: 2.8;
}

.th-price,
.th-amount,
.th-sum,
.th-op {
  text-align: center;
}
</style>
